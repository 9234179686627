$(function() {

  $(".catalog-sorting__select select").change(function() {
      if ($(this).val() != "#"){
        window.location.href = "index.php?dir=site&page=catalog&op=category&cs=" + $(this).val();
      }
  });




});
