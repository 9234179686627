$(function() {
	$(".hasSub > a").click(function(e) {
		e.preventDefault();
		//$(".sb-left .sb-menu li.hasSub, .sb-right .sb-menu li.hasSub").removeClass("hasSub-open");
		$(this).parent("li").toggleClass("hasSub-open");
		return false;
	});


	$( ".hamburger-box" ).click(function() {
		  var menu_status = $(this).parent().attr('class');
			if (menu_status.indexOf("active") == -1){
				$(".toggleForm").hide();
			} else {
				$(".toggleForm").show();
			}
	});

});
