$(function() {

  scrollFunction();

  // Side Menu Control
  var myDir = ($("html").attr('dir') == 'rtl' ? 'right' : 'left' );
  var mySlidebars = new $.slidebars();
  $('.mobile-menu-btn').on('click', function() {
      mySlidebars.slidebars.toggle(myDir);
  });

  $(window).on("scroll",scrollFunction);

  function scrollFunction() {
    if ($(window).scrollTop() > 20) {
      $(".main-header").addClass("scrolling");
      $(".logo img").attr('src',$("#small_ver_logo").val());
    } else {
      $(".main-header").removeClass("scrolling");
      $(".logo img").attr('src',$("#big_ver_logo").val());
    }
  }



  var parseQueryString = function() {
    var str = window.location.search;
    var objURL = {};

    str.replace(
        new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
        function( $0, $1, $2, $3 ){
            objURL[ $1 ] = $3;
        }
    );
    return objURL;
  };
  var params = parseQueryString();
  var page_id = ".cs_" + params["cs"] + "_" + params["page"];
  $(page_id + " a").css("color", "red");

  


});
